"use strict";

// eslint-disable-next-line no-unused-vars
// function print_label(template, shop_id, batch_id, custom) {
//   console.log("print_label_mqtt:", shop_id, custom);

//   return $.ajax({
//     method: "POST",
//     url: "/api/v3/print/bean",
//     contentType: "application/json",
//     data: JSON.stringify({
//       template: template,
//       shop: shop_id,
//       custom: custom,
//       id: batch_id,
//     }),
//   });
// }

// eslint-disable-next-line no-unused-vars
// function print_detail_label(shop_id, batch_id, custom) {
//   console.log("print_label_mqtt:", shop_id, custom);

//   return $.ajax({
//     method: "POST",
//     url: "/api/v3/print/bean",
//     contentType: "application/json",
//     data: JSON.stringify({
//       template: "detail",
//       shop: shop_id,
//       custom: custom,
//       id: batch_id,
//     }),
//   });
// }

// eslint-disable-next-line no-unused-vars
// function print_custom_label(sku) {
//   console.log("bean custom page:", sku);

//   $(".ui.modal.input")
//     .modal({
//       onHide: function () {
//         $("#title-container").removeClass("error");
//       },
//       onDeny: function () {
//         $("#title-container").removeClass("error");
//       },
//       onApprove: function () {
//         console.log($("input#title").val());
//         if ($("input#title").val() == "") {
//           $("#title-container").addClass("error");
//           return false;
//         } else {
//           if (sku && sku !== "") {
//             $.ajax({
//               method: "POST",
//               url: "/api/v2/print/bean",
//               contentType: "application/json",
//               data: JSON.stringify({
//                 shop: shopid,
//                 sku: sku,
//                 data: {
//                   bean_id: jQuery("input#bean_id").val(),
//                   price: jQuery("input#price").val(),
//                   taste: jQuery("input#taste").val(),
//                   country: jQuery("input#country").val(),
//                   region: jQuery("input#region").val(),
//                   roast: jQuery("input#roast_value").val(),
//                   batch: jQuery("input#title").val(),
//                   is_premium: jQuery("input#is_premium").val(),
//                   desc: jQuery("input#desc").val(),
//                   short_name: jQuery("input#short_name").val(),
//                   long_name: jQuery("input#long_name").val(),
//                 },
//               }),
//             })
//               //- $.ajax({
//               //-   method: "POST",
//               //-   url: "/api/v2/print/custom",
//               //-   contentType: "application/json",
//               //-   data: JSON.stringify({
//               //-     "shop": shopid,
//               //-     "data": {
//               //-       "title": jQuery("input#sku_name").val(),
//               //-       "subtitle": jQuery("input#title").val(),
//               //-     }
//               //-   })
//               //- })
//               .done(function (json) {
//                 console.log("success", json);
//                 $(".ui.modal.printing").modal("show");
//               })
//               .fail(function (json) {
//                 console.log("error", json);
//                 $(".ui.modal.error .content").text("無法連線到伺服器");
//                 $(".ui.modal.error").modal("show");
//               })
//               .always(function () {
//                 //- console.log( "complete" );
//               });
//           } else {
//             $(".ui.modal.error .content").text("咖啡豆沒有設定 SKU");
//             $(".ui.modal.error").modal("show");
//           }
//         }
//       },
//     })
//     .modal("show");
// }

// eslint-disable-next-line no-unused-vars
// function print_detail_label(sku) {
//   console.log("bean detail page:", sku);

//   if (sku && sku !== "") {
//     $.ajax({
//       method: "POST",
//       url: "/api/v2/print/detail",
//       contentType: "application/json",
//       data: JSON.stringify({
//         shop: shopid,
//         sku: sku,
//         data: {
//           bean_id: jQuery("input#bean_id").val(),
//           price: jQuery("input#price").val(),
//           taste: jQuery("input#taste").val(),
//           country: jQuery("input#country").val(),
//           region: jQuery("input#region").val(),
//           roast: jQuery("input#roast").val(),
//           batch: jQuery("input#sku_batch").val(),
//           is_premium: jQuery("input#is_premium").val(),
//           desc: jQuery("input#desc").val(),
//           short_name: jQuery("input#short_name").val(),
//           long_name: jQuery("input#long_name").val(),
//         },
//       }),
//     })
//       .done(function (json) {
//         console.log("success", json);
//         $(".ui.modal.printing").modal("show");
//       })
//       .fail(function (json) {
//         console.log("error", json);
//         $(".ui.modal.error .content").text("無法連線到伺服器");
//         $(".ui.modal.error").modal("show");
//       })
//       .always(function () {
//         //- console.log( "complete" );
//       });
//   } else {
//     $(".ui.modal.error .content").text("咖啡豆沒有設定 SKU");
//     $(".ui.modal.error").modal("show");
//   }
// }

// eslint-disable-next-line no-unused-vars
// function print_custom_detail_label(sku) {
//   console.log("bean custom page:", sku);

//   $(".ui.modal.input")
//     .modal({
//       onHide: function () {
//         $("#title-container").removeClass("error");
//       },
//       onDeny: function () {
//         $("#title-container").removeClass("error");
//       },
//       onApprove: function () {
//         console.log($("input#title").val());
//         if ($("input#title").val() == "") {
//           $("#title-container").addClass("error");
//           return false;
//         } else {
//           if (sku && sku !== "") {
//             $.ajax({
//               method: "POST",
//               url: "/api/v2/print/detail",
//               contentType: "application/json",
//               data: JSON.stringify({
//                 shop: shopid,
//                 sku: sku,
//                 data: {
//                   bean_id: jQuery("input#bean_id").val(),
//                   price: jQuery("input#price").val(),
//                   taste: jQuery("input#taste").val(),
//                   country: jQuery("input#country").val(),
//                   region: jQuery("input#region").val(),
//                   roast: jQuery("input#roast").val(),
//                   batch: jQuery("input#title").val(),
//                   is_premium: jQuery("input#is_premium").val(),
//                   desc: jQuery("input#desc").val(),
//                   short_name: jQuery("input#short_name").val(),
//                   long_name: jQuery("input#long_name").val(),
//                 },
//               }),
//             })
//               .done(function (json) {
//                 console.log("success", json);
//                 $(".ui.modal.printing").modal("show");
//               })
//               .fail(function (json) {
//                 console.log("error", json);
//                 $(".ui.modal.error .content").text("無法連線到伺服器");
//                 $(".ui.modal.error").modal("show");
//               })
//               .always(function () {
//                 //- console.log( "complete" );
//               });
//           } else {
//             $(".ui.modal.error .content").text("咖啡豆沒有設定 SKU");
//             $(".ui.modal.error").modal("show");
//           }
//         }
//       },
//     })
//     .modal("show");
// }

// eslint-disable-next-line no-unused-vars
// function print_cup_label_a4(sku) {
//   console.log("bean cup A4 page:", sku);
// }

// eslint-disable-next-line no-unused-vars
// function print_cup_label(sku) {
//   console.log("bean cup page:", sku);

//   if (shopid != 1) {
//     $(".ui.modal.error .content").text("只有總店才能印 外帶杯 標籤");
//     $(".ui.modal.error").modal("show");
//     return;
//   }

//   if (sku && sku !== "") {
//     $.ajax({
//       method: "POST",
//       url: "/api/v2/print/cup",
//       contentType: "application/json",
//       data: JSON.stringify({
//         shop: 2, // TODO: do not hardcode
//         sku: sku,
//         data: {
//           bean_id: jQuery("input#bean_id").val(),
//           price: jQuery("input#price").val(),
//           taste: jQuery("input#taste").val(),
//           country: jQuery("input#country").val(),
//           region: jQuery("input#region").val(),
//           roast: jQuery("input#roast").val(),
//           batch: jQuery("input#sku_batch").val(),
//           is_premium: jQuery("input#is_premium").val(),
//           desc: jQuery("input#desc").val(),
//           short_name: jQuery("input#short_name").val(),
//           long_name: jQuery("input#long_name").val(),
//         },
//       }),
//     })
//       .done(function (json) {
//         console.log("success", json);
//         $(".ui.modal.printing").modal("show");
//       })
//       .fail(function (json) {
//         console.log("error", json);
//         $(".ui.modal.error .content").text("無法連線到伺服器");
//         $(".ui.modal.error").modal("show");
//       })
//       .always(function () {
//         //- console.log( "complete" );
//       });
//   } else {
//     $(".ui.modal.error .content").text("咖啡豆沒有設定 SKU");
//     $(".ui.modal.error").modal("show");
//   }
// }

// eslint-disable-next-line no-unused-vars
// function print_small_label(sku) {
//   console.log("bean small page:", sku);

//   if (shopid != 1) {
//     $(".ui.modal.error .content").text("只有總店才能印 小標籤");
//     $(".ui.modal.error").modal("show");
//     return;
//   }

//   if (sku && sku !== "") {
//     $.ajax({
//       method: "POST",
//       url: "/api/v2/print/small",
//       contentType: "application/json",
//       data: JSON.stringify({
//         shop: 2, // TODO: do not hardcode
//         sku: sku,
//         data: {
//           bean_id: jQuery("input#bean_id").val(),
//           price: jQuery("input#price").val(),
//           taste: jQuery("input#taste").val(),
//           country: jQuery("input#country").val(),
//           region: jQuery("input#region").val(),
//           roast: jQuery("input#roast").val(),
//           batch: jQuery("input#sku_batch").val(),
//           is_premium: jQuery("input#is_premium").val(),
//           desc: jQuery("input#desc").val(),
//           short_name: jQuery("input#short_name").val(),
//           long_name: jQuery("input#long_name").val(),
//           sku_name: jQuery("input#sku_name").val(),
//         },
//       }),
//     })
//       .done(function (json) {
//         console.log("success", json);
//         $(".ui.modal.printing").modal("show");
//       })
//       .fail(function (json) {
//         console.log("error", json);
//         $(".ui.modal.error .content").text("無法連線到伺服器");
//         $(".ui.modal.error").modal("show");
//       })
//       .always(function () {
//         //- console.log( "complete" );
//       });
//   } else {
//     $(".ui.modal.error .content").text("咖啡豆沒有設定 SKU");
//     $(".ui.modal.error").modal("show");
//   }
// }

